import React, { Component } from 'react';
import { multilanguage } from 'redux-multilanguage';
import { withRouter } from 'react-router';
import GiftCards from '../modules/Certificates';
import Print from './printbtn';
import QRCode from 'qrcode.react';

class giftCertificate extends Component {
  state = {
    id: '',
    amount: 0,
    company: {
      address: '',
      country: '',
      name: '',
      phone: '',
      state: '',
      zip_code: '',
    },
    buyer: {
      first_name: '',
      last_name: '',
    },
    recipient: {
      first_name: '',
      last_name: '',
    },
  };

  componentDidMount() {
    const { match } = this.props;
    const { uuid, emailUUID } = match.params;
    const url = emailUUID ? `${uuid}/${emailUUID}` : uuid;

    GiftCards.findByUUID(url)
      .then(certificate => {
        if (emailUUID) {
          const currentURL = window.location.href;
          let redirect = currentURL.slice(0, currentURL.lastIndexOf('/'));
          window.history.pushState('', '', redirect);
        }
        this.setState({
          ...certificate.data,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  getAmount = () => {
    const { amount } = this.state;
    if (amount.toString().length < 3) {
      return amount.toFixed(2);
    }
    return amount;
  };

  render() {
    const { id, company, recipient, buyer } = this.state;

    if (!id) {
      // TODO: No GF found, invalid UUID
      return <div />;
    }
    return (
      <div className="full-bg min-heigh">
        <Print />
        <div className="gift-certificate">
          <div className="left">
            <div className="company">
              <div className="logo">
                {company.img_url && <img src={company.img_url} alt="logo" />}
              </div>
            </div>
          </div>
          <div className="right">
            <div className="head">
              <span className="title">CERTIFICAT CADEAU</span>
            </div>
            <div className="amount-wrapper">
              <span className="amount">{this.getAmount()}$</span>
            </div>
            <div className="bottom">
              <div className="customer">
                <p className="to">
                  <span>Pour: </span>
                  <span className="names">{`${recipient.first_name} ${
                    recipient.last_name
                  }`}</span>
                </p>
                <p className="from">
                  <span>De: </span>
                  <span>{`${buyer.first_name} ${buyer.last_name}`}</span>
                </p>
              </div>
              <div className="id">{`${id}`}</div>
            </div>
          </div>
        </div>
        <div className="QR-wrapper">
          <QRCode
            value={`${window.location.origin}/dashboard/certificates/${id}`}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(multilanguage(giftCertificate));
