import axios from "axios";

class Certificates {
  static getPK = companyID => {
    return axios
      .get(`/companies/${companyID}/pk`)
      .then(resp => resp.data)
      .then(keys => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
          return keys.data.stripe_pk_test;
        }
        return keys.data.stripe_pk;
      });
  };

  static getOne = companyID => {
    return axios.get(`/companies/${companyID}`).then(resp => resp.data);
  };
}

export default Certificates;
