export const validate = state => {
  const {
    buyerName,
    buyerLastName,
    buyerEmail,
    recipientName,
    recipientLastName,
    amount,
  } = state;

  let error = {};
  let errors = [];

  if (buyerName.length < 3) {
    error.buyerName = true;
    errors.push('Buyer name is too short');
  }

  if (buyerLastName.length < 3) {
    error.buyerLastName = true;
    errors.push('Buyer last name is too short');
  }

  if (recipientName.length < 3) {
    error.recipientName = true;
    errors.push('Recipient name is too short');
  }

  if (recipientLastName.length < 3) {
    error.recipientLastName = true;
    errors.push('Recipient last name is too short');
  }

  var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  if (!re.test(String(buyerEmail).toLowerCase())) {
    error.buyerEmail = true;
    errors.push('Invalid email');
  }

  if (amount < 1) {
    error.amount = true;
    errors.push('Amount is too low');
  } else if (amount > 1000) {
    error.amount = true;
    errors.push('Amount is too high');
  }

  return { error, errors };
};
