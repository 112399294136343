import React from 'react';

const CenteredContainer = props => {
  const classNames = props.className ? ` ${props.className}` : '';
  return (
    <div className={`centered-container${classNames}`}>{props.children}</div>
  );
};

export default CenteredContainer;
