import React, { Component } from 'react';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';

class printbtn extends Component {
  print = () => {
    window.print();
  };
  render() {
    return (
      <div className="print-btn">
        <Button onClick={this.print}>
          <span className="print-label">Imprimer</span>
          <PrintIcon />
        </Button>
      </div>
    );
  }
}

export default printbtn;
