import axios from "axios";

class Certificates {
  static findAll = () => {
    return axios.get("/certificates").then((resp) => resp.data);
  };

  static find = (id) => {
    return axios.get(`/certificates/${id}`).then((resp) => resp.data);
  };

  static findByUUID = (uuid) => {
    return axios.get(`/certificates/token/${uuid}`).then((resp) => resp.data);
  };

  static update = (id, amount) => {
    return axios
      .put(`/certificates/${id}`, { amount })
      .then((resp) => resp.data);
  };

  static charge3ds = (
    paymentMethodId,
    companyID,
    buyer,
    recipient,
    certificate,
    stripe
  ) => {
    return axios
      .post(`/certificates/charge3ds`, {
        paymentMethodId,
        companyID,
        buyer,
        recipient,
        certificate,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        if (resp.data.requiresAction) {
          return stripe
            .handleCardAction(resp.data.clientSecret)
            .then((data) => {
              if (data.error) {
                return {
                  status: "error",
                  data: { message: "cardDenied" },
                };
              } else if (
                data.paymentIntent.status === "requires_confirmation"
              ) {
                return axios
                  .post(`/certificates/confirm`, {
                    companyID,
                    buyer,
                    recipient,
                    paymentIntentId: data.paymentIntent.id,
                  })
                  .then((resp) => resp.data);
              }
            });
        }
        return resp;
      })
      .catch((err) => console.log(err));
  };
}

export default Certificates;
