import React, { Component } from 'react';
import { multilanguage, changeLanguage } from 'redux-multilanguage';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 100,
    textAlign: 'center',
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});
class LanguageSelector extends Component {
  componentDidMount = () => {
    if (!this.props.saveLocally) {
      localStorage.removeItem('currentLanguage');
    }
  };

  changeLanguage = e => {
    const languageCode = e.target.value;
    localStorage.setItem('currentLanguageCode', languageCode);
    this.props.dispatch(changeLanguage(languageCode));
  };

  render() {
    const { currentLanguageCode, classes } = this.props;
    if (!this.props.saveLocally) {
      return (
        <div className="languageselector-wrapper">
          <i className="fa fa-globe-americas" aria-hidden="true" />
          <FormControl className={classes.formControl}>
            <Select
              value={currentLanguageCode}
              style={{ color: 'white' }}
              onChange={this.changeLanguage}
              inputProps={{
                name: 'lang',
                id: 'lang',
              }}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="fr">Français</MenuItem>
            </Select>
          </FormControl>
        </div>
      );
    }
    return null;
  }
}

export default multilanguage(withStyles(styles)(LanguageSelector));
