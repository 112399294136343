import React, { Component } from 'react';
import { multilanguage } from 'redux-multilanguage';
import GiftCards from '../modules/Certificates';
import ReactTable from 'react-table';
import moment from 'moment';
import 'react-table/react-table.css';
import { withRouter } from 'react-router';

class Certificates extends Component {
  state = {
    certificates: [],
  };

  componentDidMount() {
    GiftCards.findAll()
      .then(resp => {
        if (resp.status === 'success') {
          this.setState({ certificates: resp.data });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { certificates } = this.state;
    const { history } = this.props;

    const columns = [
      {
        Header: 'ID',
        accessor: 'id',
        filterMethod: (filter, row) => {
          return row[filter.id] === parseInt(filter.value);
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: props => props.value.toFixed(2),
        filterMethod: (filter, row) => {
          return String(row[filter.id]).startsWith(filter.value.toUpperCase());
        },
      },
      {
        Header: 'Buyer Last name',
        accessor: 'buyerLastName',
        filterMethod: (filter, row) => {
          return row[filter.id]
            .toUpperCase()
            .includes(filter.value.toUpperCase());
        },
        sortMethod: (a, b) => {
          return a.localeCompare(b);
        },
      },
      {
        Header: 'Buyer email',
        accessor: 'buyerEmail',
        filterMethod: (filter, row) => {
          return row[filter.id]
            .toUpperCase()
            .includes(filter.value.toUpperCase());
        },
        sortMethod: (a, b) => {
          return a.localeCompare(b);
        },
      },
      {
        Header: 'Recipient Last name',
        accessor: 'recipientLastName',
        filterMethod: (filter, row) => {
          return row[filter.id]
            .toUpperCase()
            .includes(filter.value.toUpperCase());
        },
        sortMethod: (a, b) => {
          return a.localeCompare(b);
        },
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        filterable: false,
        Cell: props => moment(props.value).format('DD/MM/YYYY HH:MM'),
        sortMethod: (a, b) => {
          return moment(a).isAfter(b);
        },
      },
    ];

    return (
      <div>
        <ReactTable
          filterable
          data={certificates}
          columns={columns}
          defaultPageSize={10}
          getTrProps={(state, rowInfo) => ({
            onClick: () =>
              history.push('./certificates/' + rowInfo.original.id),
          })}
        />
      </div>
    );
  }
}

export default withRouter(multilanguage(Certificates));
