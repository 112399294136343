import axios from 'axios';
import cookie from '../utils/Cookie-utils';

class Auth {
  static login = (username, password) => {
    return axios
      .post('/auth/login', {
        username,
        password,
      })
      .then(resp => resp.data.user)
      .then(user => {
        if (user) {
          axios.defaults.headers.common.Authorization = `JWT ${user.token}`;
          cookie.setItem('ingift_token', user.token);
        }
        return user;
      });
  };

  static isConnected = async () => {
    const token = await cookie.getItem('ingift_token');
    if (token) {
      return true;
    }

    return false;
  };

  static logout = () =>
    new Promise(resvolve => {
      cookie.removeItem('ingift_token');
      axios.defaults.headers.common.Authorization = null;
      resvolve();
    });
}

export default Auth;
