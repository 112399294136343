function getItem(cname) {
  return new Promise(resolve => {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        resolve(c.substring(name.length, c.length));
      }
    }
    resolve('');
  });
}

function setItem(name, item) {
  document.cookie = `${name}=${item}; Max-Age=2147483647;path=/;`;
}

function removeItem(name) {
  document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
}

module.exports = {
  getItem,
  setItem,
  removeItem,
};
