import React, { Component } from "react";
import { multilanguage } from "redux-multilanguage";
import { CardElement, injectStripe } from "react-stripe-elements";
import GiftCards from "../modules/Certificates";
import { validate } from "./helpers/BuyFormHelpers";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Loader from "./Loader";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { withRouter } from "react-router";
import util from "util";
import Companies from "../modules/Companies";

const certificateImg = require("../images/certificate/icone_email.svg");

const styles = (theme) => ({
  underline: {
    "&:after": {
      borderBottom: "2px solid #8a95a5",
    },
  },
});

class BuyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buyerName: "",
      buyerLastName: "",
      buyerEmail: "",
      recipientName: "",
      recipientLastName: "",
      amount: "",
      url: "",
      error: {},
      errors: [],
      hideForm: false,
      loading: {
        status: false,
        text: "",
      },
      company: {},
    };
    this.submit = this.submit.bind(this);
    this.cardChanged = false;
  }

  componentDidMount() {
    const { match } = this.props;

    Companies.getOne(match.params.id)
      .then((resp) => resp.data)
      .then((company) => {
        this.setState({
          company,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleChange = (name) => (event) => {
    const re = /^[0-9\b]+$/;
    switch (name) {
      case "amount":
        if (event.target.value === "" || re.test(event.target.value)) {
          this.setState({
            [name]: event.target.value,
          });
        }
        break;
      default:
        this.setState({
          [name]: event.target.value,
        });
        break;
    }
  };

  submit(ev) {
    const { strings } = this.props;

    this.setState({
      loading: {
        status: true,
        text: strings.BuyForm.validate,
      },
    });

    const {
      amount,
      buyerName,
      buyerLastName,
      buyerEmail,
      recipientName,
      recipientLastName,
    } = this.state;

    const { error, errors } = validate(this.state);

    if (errors.length === 0 && this.cardChanged) {
      this.setState({
        hideForm: true,
        loading: {
          status: true,
          text: strings.BuyForm.paymentProcessing,
        },
      });

      const { match } = this.props;
      const cardElement = this.props.elements.getElement("card");

      const recipient = {
        firstName: recipientName,
        lastName: recipientLastName,
      };

      const buyer = {
        firstName: buyerName,
        lastName: buyerLastName,
        email: buyerEmail,
      };

      this.props.stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: `${buyerName} ${buyerLastName}`,
            email: buyerEmail,
          },
        })
        .then((resp) => {
          if (resp.paymentMethod) {
            GiftCards.charge3ds(
              resp.paymentMethod.id,
              match.params.id,
              buyer,
              recipient,
              {
                amount: amount,
              },
              this.props.stripe
            ).then((resp) => {
              console.log("resp", resp);
              if (resp.status === "error") {
                this.setState({
                  error: {
                    process: {
                      status: true,
                      text: strings.BuyForm[resp.data.message],
                    },
                  },
                  loading: {
                    status: false,
                  },
                });
              }

              const url =
                window.location.origin +
                `/restaurant/${match.params.id}/${resp.data.code}`;

              this.setState({
                loading: {
                  status: false,
                  text: strings.BuyForm.Done,
                },
                url,
              });
            });
          }
        })
        .catch((err) => {
          this.setState({
            error: {
              process: {
                status: true,
                text: strings.BuyForm.paymentNotSucceed,
              },
            },
            loading: {
              status: false,
            },
          });
        });
    } else {
      this.setState({ loading: { status: false }, error, errors });
    }
  }

  render() {
    const {
      buyerName,
      buyerLastName,
      buyerEmail,
      recipientName,
      recipientLastName,
      amount,
      url,
      error,
      loading,
      hideForm,
      company,
    } = this.state;

    const { strings, classes } = this.props;

    if (error.process) {
      return (
        <div className="msg-wrapper error">
          <ErrorOutline />
          <p>{error.process.text}</p>
        </div>
      );
    }

    if (!loading.status && hideForm) {
      return (
        <div className="msg-wrapper">
          <img height="200" src={certificateImg} alt="" />
          <p>{strings.BuyForm.Success}</p>
          {url && (
            <div>
              <a href={url}>{strings.BuyForm.ClickHere}</a>
            </div>
          )}
        </div>
      );
    }

    return (
      <div>
        {loading.status && <Loader text={loading.text} />}
        <div className={hideForm ? "buy-form hide" : "buy-form"}>
          <h1>{strings.BuyForm.Title}</h1>
          {company.img_url ? (
            <img
              src={company.img_url}
              width={225}
              height={225}
              alt="Company Logo"
            />
          ) : (
            <div className="img-placeholder" />
          )}
          <div className="inputs-wrapper">
            <TextField
              error={error.buyerName}
              label={strings.BuyForm.BuyerFirstName}
              margin="normal"
              value={buyerName}
              onChange={this.handleChange("buyerName")}
              InputProps={{
                className: classes.underline,
              }}
              InputLabelProps={{
                style: { color: "#020202" },
              }}
            />
            <TextField
              error={error.buyerLastName}
              label={strings.BuyForm.BuyerLastName}
              margin="normal"
              value={buyerLastName}
              onChange={this.handleChange("buyerLastName")}
              InputProps={{
                className: classes.underline,
              }}
              InputLabelProps={{
                style: { color: "#020202" },
              }}
            />
            <TextField
              error={error.buyerEmail}
              label={strings.BuyForm.BuyerEmail}
              margin="normal"
              value={buyerEmail}
              onChange={this.handleChange("buyerEmail")}
              InputProps={{
                className: classes.underline,
              }}
              InputLabelProps={{
                style: { color: "#020202" },
              }}
            />
            <TextField
              error={error.recipientName}
              label={strings.BuyForm.RecipientFirstName}
              margin="normal"
              value={recipientName}
              onChange={this.handleChange("recipientName")}
              InputProps={{
                className: classes.underline,
              }}
              InputLabelProps={{
                style: { color: "#020202" },
              }}
            />
            <TextField
              error={error.recipientLastName}
              label={strings.BuyForm.RecipientLastName}
              margin="normal"
              value={recipientLastName}
              onChange={this.handleChange("recipientLastName")}
              InputProps={{
                className: classes.underline,
              }}
              InputLabelProps={{
                style: { color: "#020202" },
              }}
            />
            <TextField
              error={error.amount}
              label={strings.BuyForm.Amount}
              margin="normal"
              value={amount}
              onChange={this.handleChange("amount")}
              InputLabelProps={{
                style: { color: "#020202" },
              }}
              InputProps={{
                inputProps: { min: 1 },
                className: classes.underline,
              }}
            />
            <CardElement onChange={() => (this.cardChanged = true)} />
            <div className="spacer" />
            {amount && amount > 0 && (
              <div className="disclaim">
                <p>{util.format(strings.BuyForm.Disclaim, amount)}</p>
                <p>{strings.BuyForm.NoRefund}</p>
              </div>
            )}
            <div className="button-wrapper">
              <Button variant="outlined" onClick={this.submit}>
                {strings.BuyForm.Buy}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  injectStripe(multilanguage(withStyles(styles)(BuyForm)))
);
