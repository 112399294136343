import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { createMultilanguageReducer } from "redux-multilanguage";
import cookie from "./utils/Cookie-utils";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({
    currentLanguageCode: localStorage.currentLanguageCode
      ? localStorage.currentLanguageCode
      : "fr",
  }),
});

const store = createStore(rootReducer);

axios.defaults.baseURL = "https://in-gift.herokuapp.com";
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:8080";
}

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    console.log("error.response", error.response);
    if (error.response && 401 === error.response.status) {
      localStorage.setItem("login_cb", window.location.pathname);
      window.location.href = window.location.origin;
    } else {
      return Promise.reject(error);
    }
  }
);

axios.interceptors.request.use(
  (config) => {
    config.headers.language = localStorage.currentLanguageCode
      ? localStorage.currentLanguageCode
      : "fr";
    if (!config.headers.common.Authorization) {
      cookie.getItem("ingift_token").then((token) => {
        config.headers.common.Authorization = `JWT ${token}`;
      });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
