import React, { Component } from "react";
import { multilanguage } from "redux-multilanguage";
import CenteredContainer from "./CenteredContainer";
import UpperPage from "./UpperPage";
import Auth from "../modules/Auth";
import { toast } from "react-toastify";

const LoginImg = require("../images/login/img_login.png");

class LoginPage extends Component {
  state = {
    username: "",
    password: ""
  };

  componentDidMount() {
    const { history } = this.props;
    Auth.isConnected().then(ok => {
      if (ok) {
        history.push("/dashboard/certificates");
      }
    });
  }

  keyDown = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      this.connect();
    }
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  connect = () => {
    const { username, password } = this.state;
    const { history } = this.props;

    Auth.login(username.toLowerCase(), password)
      .then(data => {
        if (data) {
          const cb = localStorage.getItem("login_cb");
          if (cb) {
            history.push(cb);
            localStorage.removeItem("login_cb");
          } else {
            history.push("/dashboard/certificates");
          }
        }
      })
      .catch(err => {
        if (
          err.response.data.status === "User not found" ||
          err.response.data.data.message === "Incorrect username" ||
          err.response.data.data.message === "Incorrect password"
        ) {
          toast.error("L'utilisateur et le mot de passe ne correspondent pas");
          this.setState({
            error: "L'utilisateur et le mot de passe ne correspondent pas"
          });
        } else {
          console.log(err);
        }
      });
  };

  render() {
    const { username, password, error } = this.state;
    return (
      <div className="grey-bg">
        <UpperPage hideMenu />
        <CenteredContainer className="login-container no-padding">
          <div className="login-wrapper">
            <div className="login">
              <h3>Identifiez-vous</h3>
              <div className="error">{error}</div>
              <input
                id="standard-dense"
                label="Username"
                className={""}
                margin="normal"
                value={username}
                onChange={this.handleChange("username")}
                onKeyDown={this.keyDown}
              />
              <input
                id="standard-password-input"
                label="Password"
                type="password"
                autoComplete="current-password"
                margin="normal"
                value={password}
                onChange={this.handleChange("password")}
                onKeyDown={this.keyDown}
              />
              <div className="button-wrapper">
                <button onClick={this.connect}>SE CONNECTER</button>
              </div>
            </div>
            <div className="images">
              <img src={LoginImg} alt="" />
            </div>
          </div>
        </CenteredContainer>
      </div>
    );
  }
}

export default multilanguage(LoginPage);
