import React, { Component } from "react";
import {
  multilanguage,
  changeLanguage,
  loadLanguages
} from "redux-multilanguage";
import { connect } from "react-redux";
import "./scss/App.scss";
import "react-toastify/dist/ReactToastify.css";
import LoginPage from "./components/LoginPage";
import Dashboard from "./containers/DashboardContainer";
import Restaurant from "./containers/RestaurantContainer";
import { Switch, Route } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

class App extends Component {
  componentDidMount() {
    this.loadLanguages();
  }

  loadLanguages() {
    this.props.dispatch(
      loadLanguages({
        languages: {
          fr: require("./languages/fr.json"),
          en: require("./languages/en.json")
        }
      })
    );
  }

  changeLanguage = e => {
    const languageCode = e.target.value;
    this.props.dispatch(changeLanguage(languageCode));
  };
  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/restaurant" component={Restaurant} />
          </Switch>
        </Router>
        <ToastContainer />
      </>
    );
  }
}

export default connect()(multilanguage(App));
