import React, { Component } from "react";
import { multilanguage } from "redux-multilanguage";
import moment from "moment";
import GiftCards from "../modules/Certificates";
import { toast } from "react-toastify";

class Certificate extends Component {
  state = {
    id: "",
    amount: "",
    code: "",
    date: "",
    buyer: {},
    recipient: {},
    companyID: "",
    usedAmount: "",
    balance: 0,
    error: false,
  };

  componentDidMount() {
    const { match } = this.props;
    GiftCards.find(match.params.id)
      .then((resp) => {
        this.setState({
          id: resp.data.id,
          amount: resp.data.amount.toFixed(2),
          usedAmount: resp.data.amount.toFixed(2),
          code: resp.data.code,
          date: resp.data.created_at,
          buyer: resp.data.buyer,
          recipient: resp.data.recipient,
          companyID: resp.data.company_id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  charge = () => {
    const { amount, usedAmount } = this.state;
    const { match } = this.props;

    if (usedAmount && usedAmount > 0 && parseFloat(usedAmount) <= amount) {
      const newAmount = amount - usedAmount;
      GiftCards.update(match.params.id, newAmount)
        .then((resp) => {
          toast.success("Nouveau montant appliqué");
          this.setState({
            amount: newAmount.toFixed(2),
            usedAmount: "",
            error: false,
          });
        })
        .catch((err) => {
          toast.error("Un erreur s'est produit");
          console.log(err);
        });
    } else {
      this.setState({ error: true });
    }
  };

  usedAmountChanged = ({ target: { value } }) => {
    const { amount } = this.state;
    const usedAmount = value ? parseFloat(value, 10).toString() : 0;
    if (amount - usedAmount >= 0 && usedAmount >= 0) {
      this.setState({
        usedAmount,
        balance: (amount - usedAmount).toFixed(2),
      });
    }
  };

  render() {
    const {
      id,
      amount,
      code,
      date,
      buyer,
      recipient,
      companyID,
      usedAmount,
      balance,
    } = this.state;

    const creationDate = moment(date).format("DD/MM/YYYY HH:MM");
    return (
      <div className="certificate container-sidebar">
        <div className="left">
          <h2>Information du certificat cadeau</h2>
          <div className="gc-header">
            <div className="info">
              <p>
                {"ID: "}
                <strong>{id}</strong>
              </p>
              <p>
                {"Montant: "}
                <strong>{amount}</strong>
              </p>
              <p>
                {"Code de référence: "}
                <a
                  href={
                    window.location.origin + `/restaurant/${companyID}/${code}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>{code}</strong>
                </a>
              </p>
              <p>
                {"Date: "} <strong>{creationDate}</strong>
              </p>
            </div>
            <div className="img" />
          </div>
          <div className="gc-people">
            <div className="buyer">
              <p>Acheteur</p>
              <p>
                <strong>{`${buyer.first_name} ${buyer.last_name}`}</strong>
              </p>
              <p>
                <strong>{buyer.email}</strong>
              </p>
            </div>
            <div className="recipient">
              <p>Destinataire</p>
              <p>
                <strong>{`${recipient.first_name} ${recipient.last_name}`}</strong>
              </p>
            </div>
          </div>
          <div className="historic" />
        </div>
        <div className="right">
          <h2>Montant</h2>
          <div className="charge-container">
            <div className="usedAmount-wrapper">
              {/* error={error} */}-
              <input
                type="number"
                min={1}
                max={amount}
                value={usedAmount}
                onChange={this.usedAmountChanged}
              />
              $
            </div>
          </div>
          <div className="balance-wrapper">
            BALANCE: <span className="balance">{`${balance}$`}</span>
          </div>
          <div className="button-wrapper">
            <button onClick={this.charge}>APPLIQUER</button>
          </div>
        </div>
      </div>
    );
  }
}

export default multilanguage(Certificate);
