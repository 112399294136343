import React, { Component } from 'react';
import { multilanguage } from 'redux-multilanguage';
import Companies from '../modules/Companies';
import { Elements, StripeProvider } from 'react-stripe-elements';
import BuyForm from './BuyForm';
import LanguageSelector from './LanguageSelector';
import { withRouter } from 'react-router';

class Restaurant extends Component {
  state = {
    stripePK: '',
  };

  componentDidMount() {
    const { match } = this.props;

    Companies.getPK(match.params.id)
      .then(stripe_pk => {
        this.setState({
          stripePK: stripe_pk,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { stripePK } = this.state;
    const { currentLanguageCode } = this.props;

    if (!stripePK) {
      return <div />;
    }

    return (
      <StripeProvider apiKey={stripePK}>
        <div className="full-bg">
          <div className="content-wrapper">
            <Elements locale={currentLanguageCode}>
              <BuyForm />
            </Elements>
          </div>
          <LanguageSelector />
        </div>
      </StripeProvider>
    );
  }
}

export default withRouter(multilanguage(Restaurant));
