import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import Auth from '../modules/Auth';

const certificateImg = require('../images/certificate/icone_cc-blanc.svg');

class UpperPage extends PureComponent {
  logout = () => {
    const { history } = this.props;
    Auth.logout().then(() => {
      history.push('/');
    });
  };

  render() {
    const { hideMenu } = this.props;
    return (
      <div className="upper-page">
        <h1>
          Certificats Cadeaux <img height="30" src={certificateImg} alt="" />
        </h1>
        <div className="menu">
          {!hideMenu && <Button onClick={this.logout}>Log out</Button>}
        </div>
      </div>
    );
  }
}

UpperPage.defaultProps = {
  hideMenu: false,
};

export default withRouter(UpperPage);
