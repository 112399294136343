import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

class Loader extends Component {
  render() {
    const { text } = this.props;
    return (
      <div className="loader-wrapper">
        <CircularProgress color="secondary" size={50} />
        {text && <div className="text-wrapper">{text}</div>}
      </div>
    );
  }
}

export default Loader;
