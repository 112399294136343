import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import Restaurant from '../components/Restaurant';
import giftCertificate from '../components/giftCertificate';

class RestaurantContainer extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/restaurant/:id" component={Restaurant} />
        <Route path="/restaurant/:restaurantID/:uuid/:emailUUID?" component={giftCertificate} />
      </Switch>
    );
  }
}

export default RestaurantContainer;
