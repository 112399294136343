import React, { Component } from 'react';
import { multilanguage } from 'redux-multilanguage';
import transactions from '../modules/Transactions';
import ReactTable from 'react-table';
import moment from 'moment';

class Transactions extends Component {
  state = {
    transactions: [],
  };

  componentDidMount() {
    transactions.findAll().then(resp => {
      this.setState({ transactions: resp.data });
    });
  }

  render() {
    const { transactions } = this.state;

    const columns = [
      {
        Header: 'Amount',
        accessor: 'amount',
        filterMethod: (filter, row) => {
          return String(row[filter.id]).startsWith(filter.value.toUpperCase());
        },
        Cell: props => (props.value / 100).toFixed(2),
      },
      {
        Header: 'Charge ID',
        accessor: 'charge_id',
        filterMethod: (filter, row) => {
          return row[filter.id]
            .toUpperCase()
            .includes(filter.value.toUpperCase());
        },
        sortMethod: (a, b) => {
          return a.localeCompare(b);
        },
      },
      {
        Header: 'Buyer email',
        accessor: 'email',
        filterMethod: (filter, row) => {
          return row[filter.id]
            .toUpperCase()
            .includes(filter.value.toUpperCase());
        },
        sortMethod: (a, b) => {
          return a.localeCompare(b);
        },
      },
      {
        Header: 'Stripe Token',
        accessor: 'token',
        filterMethod: (filter, row) => {
          return row[filter.id]
            .toUpperCase()
            .includes(filter.value.toUpperCase());
        },
        sortMethod: (a, b) => {
          return a.localeCompare(b);
        },
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        filterable: false,
        Cell: props => moment(props.value).format('DD/MM/YYYY HH:MM'),
        sortMethod: (a, b) => {
          return moment(a).isAfter(b);
        },
      },
    ];

    return (
      <div>
        <h1>Transactions</h1>
        <ReactTable
          filterable
          data={transactions}
          columns={columns}
          defaultPageSize={10}
        />
      </div>
    );
  }
}

export default multilanguage(Transactions);
