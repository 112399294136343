import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import Dashboard from '../components/Dashboard';
import Certificates from '../components/Certificates';
import Certificate from '../components/Certificate';
import Transactions from '../components/Transactions';
import UpperPage from '../components/UpperPage';

class DashboardContainer extends Component {
  render() {
    return (
      <div className="grey-bg">
        <div className="dashboard-container">
          <UpperPage />
          <div className="dashboard-inner">
            <Switch>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route
                exact
                path="/dashboard/certificates"
                component={Certificates}
              />
              <Route
                exact
                path="/dashboard/transactions"
                component={Transactions}
              />
              <Route
                exact
                path="/dashboard/certificates/:id"
                component={Certificate}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardContainer;
